import React from "react";
import { Button } from "@mui/material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import { profileSettingsPath } from "../routes/studentRoutes";
import { useNavigate } from "react-router-dom";

export default function ProfileSettings() {
  const navigate = useNavigate();

  const onClickPreviousProblems = () => {
    navigate(profileSettingsPath);
  };

  return (
    <Button
      onClick={onClickPreviousProblems}
      variant="contained"
      startIcon={<ManageAccountsIcon />}
      sx={{ pr: 1, mx: 1 }}
    >
      <span style={{ marginRight: "10px" }}>تعديل بياناتى الشخصية</span>
    </Button>
  );
}
