import { Box, Grid2, Typography } from "@mui/material";
import React from "react";
import DepartmentsTabs from "./DepartmentsTabs";
import { departmentsOptions } from "../../Components/CategoryManager/utils";
import StudentForm from "./StudentForm";
import { AppContext } from "../../Context/App.context";

const studentHeaderLines = [
  { color: "#C59A47", text: "عزيزي الطالب/عزيزتي الطالبة" },
  {
    color: "#C59A47",
    text: "يرجى تسجيل المشكلة التي تواجهها أثناء تسجيل المقررات الدراسية بعد اختيار القسم التابع لك",
  },
  {
    color: "#0a6a79",
    text: "نحن هنا لمساعدتك، وسيتم التعامل مع مشكلتك في أسرع وقت.",
  },
  {
    color: "#000",
    text: "الشئون الأكاديمية– كلية إدارة الأعمال – جامعة الملك فيصل",
  },
];

let yourDate = new Date();
export const problemSelectionPlaceholder = "--الرجاء تحديد المشكلة--";
export const levelSelectionPlaceholder = "--الرجاء تحديد المستوى--";
export const initialSubjects = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];

export default function StudentPage() {
  const [currentDepartment, setCurrentDepartment] = React.useState("");

  const [problemData, setProblemData] = React.useState({
    problemType: problemSelectionPlaceholder,
    level: levelSelectionPlaceholder,
    department: currentDepartment,

    createdAt: yourDate.toISOString().split("T")[0],
    isCorrectData: false,
    subjects: initialSubjects,
    constraints: [],
  });

  const onSelectDepartment = (department) => {
    setCurrentDepartment(department);
  };

  return (
    <Box sx={{ pb: 5 }}>
      <Box sx={{ textAlign: "center", my: 4 }}>
        {studentHeaderLines.map((line) => {
          return (
            <Typography
              variant="h4"
              sx={{ color: line.color, fontSize: "22px", fontWeight: "600" }}
              key={line.text}
            >
              {line.text}
            </Typography>
          );
        })}
      </Box>

      <DepartmentsTabs
        currentDepartment={currentDepartment}
        onSelectDepartment={onSelectDepartment}
      />

      {departmentsOptions.find((d) => d.label === currentDepartment)
        ?.department ? (
        <Grid2 container sx={{ mt: 3, flexDirection: "row-reverse" }}>
          <Grid2 size={{ xs: 12, md: 6 }} sx={{ textAlign: "center" }}>
            <img
              style={{ maxWidth: "90%" }}
              src={
                "/images/" +
                departmentsOptions.find((d) => d.label === currentDepartment)
                  .department +
                ".png"
              }
              alt="logo"
            />
          </Grid2>

          <Grid2 size={{ xs: 12, md: 6 }}>
            <StudentForm
              department={currentDepartment}
              problemData={problemData}
              setProblemData={setProblemData}
            />
          </Grid2>
        </Grid2>
      ) : (
        <Box sx={{ textAlign: "center", my: 4 }}>
          <img
            src={"/images/warning.png"}
            alt="logo"
            style={{ maxWidth: "90%" }}
          />
        </Box>
      )}
    </Box>
  );
}
