const mainInfo = [
  {
    name: "username",
    label: "الأسم",
  },
  {
    name: "email",
    label: "البريد الإلكترونى",
  },
  {
    name: "phone",
    label: "رقم الجوال",
  },
  {
    name: "gender",
    label: "الجنس",
  },
  {
    name: "academicNumber",
    label: "الرقم الاكاديمى",
  },
];

export const signupInputs = [
  ...mainInfo,
  {
    name: "password",
    label: "كلمة المرور",
  },
  {
    name: "confirmPassword",
    label: "أعد كتابة كلمة المرور",
  },
];

export const updateProfileInputs = [...mainInfo];

export const updateProfilePasswordInputs = [
  {
    name: "oldPassword",
    label: "كلمة المرور السابقة",
  },
  {
    name: "newPassword",
    label: "كلمة المرور الجديدة",
  },
  {
    name: "confirmNewPassword",
    label: "أعد كتابة كلمة المرور الجديدة",
  },
];

export const loginInputs = [
  {
    name: "email",
    label: "البريد الإلكترونى",
  },

  {
    name: "password",
    label: "كلمة المرور",
  },
];
