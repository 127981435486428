import axios from "../../api/axios";

export const authApi = async ({ type, data }) => {
  let res, error;

  try {
    res = await axios.post("auth/" + type, data);
  } catch (err) {
    error = err;
    console.log(error);
  }

  return { res, error };
};
