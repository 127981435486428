import { Button, Paper, Typography } from "@mui/material";
import React from "react";
import TextInput from "../../Components/inputs/TextInput";
import { loginInputs, signupInputs } from "./utils";
import { authApi } from "./api";
import RadioInput from "../../Components/inputs/RadioInput";
import { AppContext } from "../../Context/App.context";
import { useNavigate } from "react-router-dom";
import { fbSendEmailPath } from "../../routes/visitingParentRoutes";

export default function LoginPage() {
  const [currentPhase, setCurrentPhase] = React.useState("login");

  const [data, setData] = React.useState({});
  const [error, setError] = React.useState("");

  const { setCurrentUser } = React.useContext(AppContext);

  const navigate = useNavigate();

  const onChangePhase = () => {
    if (currentPhase === "login") {
      setCurrentPhase("signup");
    } else {
      setCurrentPhase("login");
    }
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setError("");
    setData({ ...data, [name]: value });
  };

  const onSubmit = async () => {
    if (currentPhase === "login") {
      if (!data.email || !data.password) {
        setError("برجاء استكمال البيانات");
      } else {
        const { res, error } = await authApi({ data, type: "login" });

        if (error?.status) {
          setError("برجاء التأكد من صحة البيانات..  وإعادة المحاولة");
        }

        if (res?.data.message) {
          setError(res.data.message);
        }

        if (res?.data.accessToken) {
          setCurrentUser(res.data.accessToken);
        }
      }
    } else {
      signupInputs.forEach((input) => {
        if (!data[input.name]) {
          setError(`برجاء إدخال ${input.label}`);
        }
      });

      const postEmail = "@kfu.edu.sa";

      if (data.email.slice(-postEmail.length) !== postEmail) {
        setError("الرجاء إدخال البريد الالكترونى الجامعى الخاص بك.");
      }

      if (data.academicNumber.toString().length !== 9) {
        setError(
          "رجاء التأكد من إدخال الرقم الأكاديمى المكون من 9 أرقام بشكل صحيح."
        );
      }

      console.log("from sign up validation : ", error);

      if (!error) {
        const { res, error } = await authApi({ data, type: "signup" });

        if (error?.status) {
          setError(error.response.data.message);
        }
        if (res) {
          navigate("/auth/post-register");
        }
      }
    }
  };
  return (
    <Paper sx={{ width: { xs: "85%", md: "600px" }, m: " 20px auto", p: 2 }}>
      {(currentPhase === "login" ? loginInputs : signupInputs).map((input) => {
        return input.name === "gender" ? (
          <RadioInput
            label={input.label}
            name={input.name}
            value={data[input.name]}
            onChange={onChange}
            options={["ذكر", "أنثى"]}
            key={input.name}
          />
        ) : (
          <TextInput
            label={input.label}
            value={data[input.name]}
            name={input.name}
            onChange={onChange}
            key={input.name}
            type={input.name === "academicNumber" && "number"}
          />
        );
      })}

      <Typography sx={{ color: "red", fontSize: "14px", textAlign: "center" }}>
        {error}
      </Typography>

      {currentPhase === "login" ? (
        <>
          <Button
            onClick={onChangePhase}
            sx={{ textDecoration: "underline", fontWeight: "600" }}
          >
            ليس عندى حساب, إنشاء حساب جديد ؟
          </Button>

          <Button
            onClick={() => {
              navigate(fbSendEmailPath);
            }}
            sx={{ textDecoration: "underline", fontWeight: "600" }}
          >
            أو نسيت كلمة المرور ؟
          </Button>
        </>
      ) : (
        <>
          <Button
            onClick={onChangePhase}
            sx={{ textDecoration: "underline", fontWeight: "600" }}
          >
            عندى حساب بالفعل ؟
          </Button>
        </>
      )}
      <Button
        onClick={onSubmit}
        variant="contained"
        fullWidth
        sx={{ mt: 1, fontSize: "18px" }}
      >
        {currentPhase === "login" ? "دخول حسابى" : "إنشاء حساب"}
      </Button>
    </Paper>
  );
}
