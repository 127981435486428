import { Box, Button, Paper, Typography } from "@mui/material";
import React from "react";
import api from "../../../api/axios";
import {
  updateProfileInputs,
  updateProfilePasswordInputs,
} from "../../LoginPage/utils";
import RadioInput from "../../../Components/inputs/RadioInput";
import TextInput from "../../../Components/inputs/TextInput";

export default function ProfileSettings() {
  const [data, setData] = React.useState({});
  const [error, setError] = React.useState("");
  const [result, setResult] = React.useState("");

  const [currentFormName, setCurrentFormName] = React.useState("info");

  React.useEffect(() => {
    const func = async () => {
      const res = await api.get("user/get-info");

      setData(res.data);
    };
    func();
  }, []);

  const onChangeFormName = () => {
    if (currentFormName === "info") {
      setCurrentFormName("password");
    } else {
      setCurrentFormName("info");
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setError("");
    setResult("");
    setData({ ...data, [name]: value });
  };

  const onSubmit = async () => {
    if (currentFormName === "info") {
      updateProfileInputs.forEach((input) => {
        if (!data[input.name]) {
          setError(`برجاء إدخال ${input.label}`);
        }
      });

      // const postEmail = "@kfu.edu.sa";

      // if (data.email.slice(-postEmail.length) !== postEmail) {
      //   setError("الرجاء إدخال البريد الالكترونى الجامعى الخاص بك.");
      // }

      if (data.academicNumber.toString().length !== 9) {
        setError(
          "رجاء التأكد من إدخال الرقم الأكاديمى المكون من 9 أرقام بشكل صحيح."
        );
      }

      console.log("from sign up validation : ", error);
    } else {
      updateProfilePasswordInputs.forEach((input) => {
        if (!data[input.name]) {
          setError(`برجاء إدخال ${input.label}`);
        }
      });

      if (data.newPassword !== data.confirmNewPassword) {
        setError(
          " برجاء التأكد من تطابق كلمة السر الجديدة وإعادة كلمة السر الجديدة"
        );
      }
    }

    if (!error) {
      setResult("");
      try {
        const res = await api.patch("user/update-" + currentFormName, data);

        if (res?.data) {
          setResult("تم تعديل البيانات");
        }
      } catch (error) {
        console.log({ error });
        setError("حدث خطأ ما");
        if (error?.status) {
          console.log(error);
          setError(error.response.data.message);
        }
      }
    }
  };

  return (
    <Box sx={{ mr: 3, mt: 5 }}>
      <Typography variant="h5" sx={{ fontWeight: "600", pb: 2 }}>
        تعديل بياناتى الشخصية
      </Typography>

      <Paper sx={{ width: { xs: "85%", md: "600px" }, m: " 10px", p: 2 }}>
        {(currentFormName === "info"
          ? updateProfileInputs
          : updateProfilePasswordInputs
        ).map((input) => {
          return input.name === "gender" ? (
            <RadioInput
              label={input.label}
              name={input.name}
              value={data[input.name]}
              onChange={onChange}
              options={["ذكر", "أنثى"]}
              key={input.name}
            />
          ) : (
            <TextInput
              label={input.label}
              value={data[input.name]}
              name={input.name}
              onChange={onChange}
              key={input.name}
              type={input.name === "academicNumber" && "number"}
              disabled={input.name === "email" ? true : false}
            />
          );
        })}

        <Typography
          sx={{ color: "red", fontSize: "14px", textAlign: "center" }}
        >
          {error}
        </Typography>

        <Typography
          sx={{ color: "primary.main", fontSize: "14px", textAlign: "center" }}
        >
          {result}
        </Typography>

        <Button
          onClick={onChangeFormName}
          sx={{ textDecoration: "underline", fontWeight: "600" }}
        >
          {currentFormName === "info"
            ? "أو تعديل كلمة المرور"
            : "أو تعديل البيانات العامة"}
        </Button>

        <Button
          onClick={onSubmit}
          variant="contained"
          fullWidth
          sx={{ mt: 1, fontSize: "18px" }}
        >
          {currentFormName === "info" ? "تعديل البيانات" : "تغيير كلمة المرور"}
        </Button>
      </Paper>
    </Box>
  );
}
