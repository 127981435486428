import React from "react";
import TextInput from "../../../Components/inputs/TextInput";
import RadioInput from "../../../Components/inputs/RadioInput";
import { AppContext } from "../../../Context/App.context";

export default function UserDataForm() {
  const { user } = React.useContext(AppContext);

  return (
    <>
      <TextInput value={user.username} label="الأسم" name="username" disabled />

      <TextInput
        value={user.email}
        label="البريد الإلكترونى"
        name="email"
        disabled
      />

      <RadioInput
        value={user.gender}
        label="الجنس"
        name="gender"
        options={["ذكر", "أنثى"]}
        disabled
      />

      <TextInput value={user.phone} label="رقم الجوال" name="phone" disabled />

      <TextInput
        value={user.academicNumber}
        label="الرقم الأكاديمى"
        name="academicNumber"
        disabled
      />
    </>
  );
}
