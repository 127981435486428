import React, { createContext, useState } from "react";
import { jwtDecode } from "../../node_modules/jwt-decode/build/esm";

const AppContext = createContext({});

function AppContextProvider({ children }) {
  const [user, setUser] = useState({ role: "visitor" });
  const [courses, setCourses] = useState([]);

  function setCurrentUser(accessToken) {
    localStorage.removeItem("token");

    localStorage.setItem("token", accessToken);
    const decodedToken = jwtDecode(accessToken);

    setUser(decodedToken);
  }

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      const expirationTime = decodedToken.exp;

      if (expirationTime < currentTime) {
        // Token has expired, handle accordingly (e.g., log out user, refresh token)
        localStorage.removeItem("token");
        setUser({ role: "visitor" });
      } else {
        // Token is still valid
        setUser(decodedToken);
      }
    }
  }, []);

  return (
    <AppContext.Provider
      value={{ user, setUser, setCurrentUser, courses, setCourses }}
    >
      {children}
    </AppContext.Provider>
  );
}

export { AppContextProvider, AppContext };
