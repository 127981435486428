import { Box } from "@mui/material";
import React from "react";
import { departmentsOptions } from "../../Components/CategoryManager/utils";

const spottedStyle = {
  backgroundColor: "primary.oldMain",
  color: "white",
  "& svg": { color: "white" },
};
export default function DepartmentsTabs({
  currentDepartment,
  onSelectDepartment,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        px: 4,
      }}
    >
      {departmentsOptions.map(({ label, icon, number }) => {
        const ifSelected = currentDepartment === label ? spottedStyle : {};
        return (
          <Box
            sx={{
              display: "inline-block",
              py: 2,
              px: 6,
              border: "1px solid #0a6a79",
              flexGrow: { xs: 1, md: 0 },
              width: { xs: "100%", sm: "auto" },
              flexShrink: 0,
              textAlign: "center",
              backgroundColor: "primary.studentTabsBackground",

              cursor: "pointer",
              "&:hover": spottedStyle,

              ...ifSelected,
            }}
            onClick={() => onSelectDepartment(label)}
            key={label}
          >
            <Box
              sx={{
                transform: "translate(5px,2px)",
                display: "inline-block",
                color: "text.secondary",
              }}
            >
              {icon}
            </Box>
            <Box
              sx={{
                transform: "translateY(-5px)",
                display: "inline-block",
                fontSize: "14px",
              }}
            >
              {label} {number}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
