import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid2,
  Tooltip,
  Typography,
} from "@mui/material";

import UserDataForm from "./UserDataForm";
import LabeledSelect from "../../../Components/inputs/LabeledSelect";
import {
  departmentsOptions,
  problemsOptions,
} from "../../../Components/CategoryManager/utils";
import FileInput from "../../../Components/inputs/FileInput";
import { problemsComponentsArray } from "../../../Components/ProblemTypes/Components/ProblemsArray";
import axios from "../../../api/axios";
import TextInput from "../../../Components/inputs/TextInput";
import { levels } from "./utils";
import { AppContext } from "../../../Context/App.context";

import FilePresentIcon from "@mui/icons-material/FilePresent";
import {
  initialSubjects,
  levelSelectionPlaceholder,
  problemSelectionPlaceholder,
} from "..";

const problemTypes = problemsOptions.map((problem) => problem.label).slice(1);
const levelTypes = levels.map((l) => l.label);

export default function StudentForm({
  problemData,
  setProblemData,
  department,
}) {
  const { courses, setCourses } = React.useContext(AppContext);

  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [resError, setResError] = React.useState("");

  const onChangeSubject = (e, id) => {
    const { name, value } = e.target;
    setError("");
    setSuccess("");

    let more = { name: "", number: "" };

    name === "name"
      ? (more = {
          number: courses.find((c) => c.subjectName === value)?.subjectNumber,
        })
      : name === "number"
      ? (more = {
          name: courses.find((c) => c.subjectNumber === value)?.subjectName,
        })
      : (more = {});

    // console.log({ name, more, courses, id });

    const newSub = (s) => {
      const res = { ...s, [name]: value, ...more };
      // console.log({ res });
      return res;
    };

    setProblemData({
      ...problemData,
      subjects: problemData.subjects.map((s) => {
        if (s.id === id) {
          return newSub(s);
        } else {
          return s;
        }
      }),
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setError("");
    setSuccess("");

    setProblemData({ ...problemData, [name]: value });
  };

  const onProblemChange = (e) => {
    const { value } = e.target;
    setError("");
    setSuccess("");
    setProblemData({ ...problemData, problemType: value });
  };

  const onLevelChange = async (e) => {
    const { value } = e.target;
    setError("");
    setSuccess("");
    setProblemData({ ...problemData, level: value });
  };

  React.useEffect(() => {
    setProblemData({
      ...problemData,
      problemType: problemSelectionPlaceholder,
      subjects: initialSubjects,
    });
    const func = async () => {
      try {
        const res = await axios.get(
          `course?department=${department}&level=${
            levels.find((l) => l.label === problemData.level).number
          }`
        );
        console.log({ res });
        // setProblemData({ ...problemData, subjects: [] });
        setCourses(res?.data || []);
      } catch (error) {
        console.log(error);
      }
    };

    func();
  }, [problemData.level, department]);

  const onChangeConstraints = (e, c) => {
    setError("");
    setSuccess("");

    if (e.target.checked) {
      setProblemData({
        ...problemData,
        constraints: [...problemData.constraints, c],
      });
    } else {
      const constraints = problemData.constraints.filter((co) => co !== c);

      setProblemData({ ...problemData, constraints });
    }
  };

  const onSelectFile = (e, name) => {
    const { files } = e.target;
    setError("");
    setSuccess("");

    console.log({ files: files[0] });
    setProblemData({ ...problemData, [name]: files[0] });
  };

  const onIsCorrectData = (e) => {
    setProblemData({ ...problemData, isCorrectData: e.target.checked });
  };

  const onSubmitProblem = async () => {
    const subjects = [];
    problemData.subjects.forEach((s) => {
      if (s.name || s.crn || s.sectionNumber) {
        if (!s.name || !s.crn || !s.sectionNumber) {
          setError("يجب استكمال بيانات المقرر " + s.id + " أو حذف بياناته");
        } else {
          delete s.id;
          subjects.push(s);
        }
      }
    });

    try {
      if (problemData.problemType === problemSelectionPlaceholder) {
        setError("رجاء تحديد المشكلة!");
      } else if (!problemTypes[4] && !subjects[0]) {
        setError("رجاء إدخال مقرر واحد على الأقل.");
      } else if (
        [
          problemTypes[0],
          problemTypes[1],
          problemTypes[3],
          problemTypes[4],
        ].includes(problemData.problemType) &&
        !problemData.gainedHours
      ) {
        setError("رجاء إدخال عدد الساعات المكتسبة.");
      } else if (
        [problemTypes[4]].includes(problemData.problemType) &&
        !problemData.requiredHours
      ) {
        setError("رجاء إدخال عدد الساعات المطلوبة فى التسجيل.");
      } else if (
        [problemTypes[2]].includes(problemData.problemType) &&
        !problemData.constraints[0]
      ) {
        setError("رجاء إختيار قيد واحد على الأقل.");
      } else if (!problemData.scheduleFile) {
        setError("رجاء إرفاق ملف الجدول الدراسى.");
      } else if (!problemData.academicFile) {
        setError("رجاء إرفاق السجل الأكاديمى");
      } else if (
        [problemTypes[2]].includes(problemData.problemType) &&
        !problemData.constraintFile
      ) {
        setError("رجاء إرفاق صورة من ملف القيد.");
      } else {
        const formData = new FormData();
        formData.append("scheduleFile", problemData.scheduleFile);
        formData.append("academicFile", problemData.academicFile);
        if (problemData.constraintFile)
          formData.append("constraintFile", problemData.constraintFile);

        // console.log({ ...problemData, subjects });
        formData.append(
          "data",
          JSON.stringify({ ...problemData, subjects, department })
        );

        if (!error) {
          // for (var pair of formData.entries()) {
          //   console.log(`${pair[0]}: ${pair[1]}`);
          // }
          const res = await axios.post("/problem", formData, {
            headers: {
              "Content-Type": "multipart/form-data", // Set content type explicitly
            },
          });
          if (res.data?.id) {
            setSuccess("تم إضافة الشكوى بنجاح.. سيتم الرد عليها فى أقرب وقت.");
            setResError("");
          } else {
            setResError("حدث خطأ فى إضافة الشكوى.. الرجاء المحاولة مرة آخرى.");
          }
        }
      }
    } catch (error) {
      console.log(error);
      setSuccess("");
      setResError("حدث خطأ فى إضافة الشكوى.. الرجاء المحاولة مرة آخرى.");
    }
  };
  return (
    <Box sx={{ width: { xs: "100%", lg: "80%" } }}>
      <UserDataForm />

      {department === "آخرى" && (
        <TextInput
          onChange={onChange}
          label="اسم التخصص الآخر"
          name="otherDepartmentName"
          value={problemData.otherDepartmentName}
        />
      )}

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ flexGrow: 1 }}>
          <LabeledSelect
            label="تحديد المستوى"
            options={levelTypes}
            placeholder={levelSelectionPlaceholder}
            value={problemData.level}
            onChange={onLevelChange}
          />
        </Box>
        <Box sx={{ p: 3 }}>
          <Tooltip title="أضغط لمعرفة مقررات المستويات" placement="top">
            <a
              href={departmentsOptions.find((d) => d.label === department).link}
              rel="noreferrer"
              target="_blank"
              style={{ color: "#777" }}
            >
              <FilePresentIcon sx={{ transform: "translateY(8px)" }} />
            </a>
          </Tooltip>
        </Box>
      </Box>

      <LabeledSelect
        label="المشكلة"
        options={problemTypes}
        placeholder={problemSelectionPlaceholder}
        value={problemData.problemType}
        onChange={onProblemChange}
      />

      {
        problemsComponentsArray({
          state: { ...problemData, department },
          onChange,
          onChangeSubject,
          subjectsList: problemData.subjects,
          onChangeConstraints,
          onSelectFile,
        }).find((p) => p.type === problemData.problemType)?.form
      }

      <Grid2 container>
        <Grid2 size={6}>
          <FileInput
            label="إرفاق الجدول الدراسى"
            name="scheduleFile"
            value={problemData.scheduleFile?.name}
            onSelectFile={onSelectFile}
          />
        </Grid2>
        <Grid2 size={6}>
          <FileInput
            label="إرفاق السجل الأكاديمى"
            name="academicFile"
            value={problemData.academicFile?.name}
            onSelectFile={onSelectFile}
          />
        </Grid2>
      </Grid2>

      <Typography sx={{ color: "red", textAlign: "center" }}>
        {error}
      </Typography>

      <FormControlLabel
        control={
          <Checkbox
            checked={problemData.isCorrectData}
            onChange={onIsCorrectData}
          />
        }
        label="أقر بصحة البيانات المدخلة"
        sx={{ color: "primary.main" }}
      />

      <Button
        onClick={onSubmitProblem}
        variant="contained"
        fullWidth
        sx={{ fontSize: "1.2rem" }}
        disabled={!problemData.isCorrectData || error}
      >
        أضف الشكوى
      </Button>
      <Typography sx={{ color: "primary.main", textAlign: "center" }}>
        {success}
      </Typography>
      <Typography sx={{ color: "red", textAlign: "center" }}>
        {resError}
      </Typography>
    </Box>
  );
}
